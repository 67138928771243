import '../styles/Footer.css'
import Logo from "../assets/stylefinderhub_banner.png";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import {Link} from "react-router-dom";


const NoMatch = () => {

    return(

        <div>
            <TopMenu back_image={Logo}/>
            <div className="about-container">
                <div>
                    <h1>404 - Page Not Found</h1>
                </div>
                <div>
                    <p>
                        Oops! The page you requested could not be found.
                        <Link to='/'> Try visiting the homepage </Link>
                    </p>
                </div>
            </div>
            <Footer/>
        </div> );

}



export default NoMatch