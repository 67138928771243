import axios from 'axios'

const DEBUG = false;
let path = "https://stylefinderhub.com";
if (DEBUG) {
    path = "http://localhost:3000";
}


export function getContent(){
    return axios({
        method:'GET',
        url: path + '/content.json',
    }).then(function(response){
        return response.data
    });
}

export function getPost(postId){
    return axios({
        method:'GET',
        url: path + '/posts/post'+ postId + '.json',
    }).then(function(response){
        return response.data
    });
}

export function getCoupons(){
    return axios({
        method:'GET',
        url: path + '/coupons.json',
    }).then(function(response){
        return response.data
    });
}